export const theme = {
  token: {
    colorLink: '#60A5FA',
    colorLinkActive: '#4A90E2',
    colorLinkHover: '#A5C9FA',
    colorPrimary: '#60A5FA',
    colorPrimaryActive: '#4A90E2',
    colorPrimaryBorder: '#FAA560',
    colorPrimaryHover: '#A5C9FA',
  },
};
